/* eslint-disable @typescript-eslint/no-namespace */
import { useEffect } from 'react'
import { Drawer } from '@/components/shared/misc'
import * as fbq from '@/utils/fpixel'
import { isLoggedIn } from '@/utils/lib'
import Router from 'next/router'
import { useRouter } from 'next/router'
import Script from 'next/script'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { RecoilRoot } from 'recoil'
import { GoogleAnalyticsProvider } from 'src/providers/shared'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { appWithTranslation } from 'next-i18next'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { FlagProvider } from '@unleash/proxy-client-react'
import { defineCustomElements } from '@qureos/qureos-components/loader'

// CSS IMPORTS
import 'antd/dist/antd.css'
import 'assets/global.scss'
import 'flag-icon-css/css/flag-icons.min.css'
import 'modules/assets/main.scss'
import 'moment-timezone'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dates/lib/css/_datepicker.css'
import { captureVisit } from 'src/services/user-analytics'
import ThemeProvider from 'src/themes/ThemeProvider'
import Head from 'next/head'
import { HotjarProvider } from '@/providers/shared/HotjarProvider'
// import { jitsuAnalyticsClient } from '@/utils/segment'
import { WorkspaceProvider } from 'src/context/workspace/workspace.context'
import { CreditsProvider } from 'src/context/corporate/credits.context'
// END OF CSS IMPORTS

// test
Router.events.on('routeChangeStart', () => {
  document.body.className = document.body.className.replace('snapjs-left', '')
})

Router.events.on('routeChangeComplete', url => {
  // @ts-ignore window.analytics undefined below
  if (window && window.analytics) {
    window.analytics.page(url)
  }
})

if (!process.env.UNLEASH_URL) {
  throw new TypeError('Missing UNLEASH_URL.')
}

const config = {
  url: process.env.UNLEASH_URL,
  clientKey: process.env.UNLEASH_CLIENT_KEY,
  refreshInterval: 15, // How often (in seconds) the client should poll the proxy for updates
  appName: process.env.UNLEASH_APP_NAME
}
const isDevelopment = ['development', 'dev'].includes(
  process.env.APP_ENV.toLowerCase()
)

const { WEB_COMPONENT_URL } = process.env

function loadScript() {
  if (isDevelopment && WEB_COMPONENT_URL) {
    const script = document.createElement('script')
    script.type = 'module'
    script.src = `${WEB_COMPONENT_URL}/build/qureos-components.esm.js`
    document.head.appendChild(script)

    const fallbackScript = document.createElement('script')
    fallbackScript.type = 'nomodule'
    fallbackScript.src = `${WEB_COMPONENT_URL}/build/qureos-components.js`
    document.head.appendChild(fallbackScript)
  }
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'qc-button': any
      'qc-should-render': any
      'qc-menu-item': any
      'qc-aside': any
      'qc-context': any
      'qc-job-cards': any
      'qc-preloader': any
      'qc-slideover': any
      'qc-accordion': any
      'qc-spinner': any
      'qc-image': any
      'qc-job-listing': any
      'qc-modal': any
      'qc-table': any
    }
  }
}

function MyApp({ Component, pageProps }) {
  // const { session } = pageProps
  const router = useRouter()

  /*------------ Remove recoil warning ----------*/

  const memoize = fn => {
    const cache = {}
    return (...args) => {
      const n = args[0]
      if (n in cache) {
        return cache[n]
      } else {
        const result = fn(n)
        cache[n] = result
        return result
      }
    }
  }

  // ignore in-browser next/js recoil warnings until its fixed.
  const mutedConsole = memoize(console => ({
    ...console,
    warn: (...args) =>
      args[0].includes('Duplicate atom key') ? null : console.warn(...args)
  }))

  global.console = mutedConsole(global.console)

  /*------------ End Remove recoil warning ----------*/

  useEffect(() => {
    if (
      isLoggedIn() &&
      !['development', 'dev'].includes(process.env.APP_ENV.toLowerCase())
    ) {
      const trackingPaths = [
        '/cohort/',
        '/webinar/',
        '/enrollment/',
        '/profile/',
        '/learning-path/'
      ]

      const endsWithTrackingPaths = [
        '/choose-plan',
        '/learning-hub',
        '/jobs',
        '/mentor',
        '/mentorship'
      ]
      const path = window.location.pathname

      if (endsWithTrackingPaths.find(p => path.endsWith(p))) {
        captureVisit(path, window.location.href)
      }

      if (trackingPaths.find(p => path.startsWith(p))) {
        captureVisit(path, window.location.href)
      }
    }
  })

  useEffect(() => {
    if (fbq.PIXEL_ENVS.includes(process.env.APP_ENV)) {
      // This pageview only triggers the first time (it's important for Pixel to have real information)
      fbq.pageview()
    }

    const handleRouteChange = () => {
      if (fbq.PIXEL_ENVS.includes(process.env.APP_ENV)) {
        fbq.pageview()
      }
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    if (WEB_COMPONENT_URL) {
      loadScript()
      if (!isDevelopment) {
        defineCustomElements()
      }
      return () => {
        const scripts = document.querySelectorAll(
          `script[src^="${WEB_COMPONENT_URL}"]`
        )
        scripts.forEach(script => script.parentNode.removeChild(script))
      }
    }
  }, [router.pathname])

  /*
  useEffect(() => {
    if (process.env.APP_ENV === 'production') {
      jitsuAnalyticsClient.page()
    }
  }, [router.asPath])*/

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      {process.env.APP_ENV === 'production' && (
        <>
          <Script
            id="gtm-start"
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-WG4H2NC');`
            }}
          />
          <Script
            id="onSignalSDK"
            src="https://cdn.onesignal.com/sdks/OneSignalSDK.js"
          />
          <Script
            id="segment-events"
            dangerouslySetInnerHTML={{
              __html: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="peDmIGCDW2m4oTACHHs2D0E96HfVuknQ";;analytics.SNIPPET_VERSION="4.15.3";
          analytics.load("peDmIGCDW2m4oTACHHs2D0E96HfVuknQ");
          analytics.page();
          }}();`
            }}
          />
          <Script
            id="first-promoter-cdn"
            dangerouslySetInnerHTML={{
              __html: `(function(w){w.fpr=w.fpr||function(){w.fpr.q = w.fpr.q||[];w.fpr.q[arguments[0]=='set'?'unshift':'push'](arguments);};})(window);
fpr("init", {cid:"v08qetnm"}); 
fpr("click");`
            }}
          />
          <Script src="https://cdn.firstpromoter.com/fpr.js" async />
          <Script
            id="json-ld-script"
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: `{
                    "@context": "https://schema.org",
                    "@type": "WebSite",
                    "url": "${process.env.APP_URL}",
                    "potentialAction": {
                      "@type": "SearchAction",
                      "target": "${process.env.APP_URL}/jobs/search/query={search_term_string}",
                      "query-input": "required name=search_term_string"
                    }
                  }`
            }}
          />
          <Script
            src="https://assets.calendly.com/assets/external/widget.js"
            type="text/javascript"
            async
          />
          <Script
            id="microsoft-clarity"
            dangerouslySetInnerHTML={{
              __html: `(function(c,l,a,r,i,t,y){
                          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                      })(window, document, "clarity", "script", "nm11yoxmyc");`
            }}
          />
          <link
            href="https://assets.calendly.com/assets/external/widget.css"
            rel="stylesheet"
          />
        </>
      )}

      {fbq.PIXEL_ENVS.includes(process.env.APP_ENV) && (
        <>
          {/* Global Site Code Pixel - Facebook Pixel */}
          <Script
            id="afterInteractive"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `
            }}
          />
        </>
      )}
      <RecoilRoot>
        <HotjarProvider>
          <ThemeProvider>
            <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID}>
              <GoogleReCaptchaProvider
                reCaptchaKey={process.env.CAPTCHA_SITE_KEY}
                scriptProps={{
                  async: false,
                  defer: false,
                  appendTo: 'head',
                  nonce: undefined
                }}
              >
                <GoogleAnalyticsProvider>
                  <FlagProvider config={config}>
                    <CreditsProvider>
                      <WorkspaceProvider>
                        <Component {...pageProps} />
                      </WorkspaceProvider>
                    </CreditsProvider>
                    <Drawer />
                  </FlagProvider>
                </GoogleAnalyticsProvider>
              </GoogleReCaptchaProvider>
            </GoogleOAuthProvider>
          </ThemeProvider>
        </HotjarProvider>
      </RecoilRoot>
      <ToastContainer autoClose={2000} limit={1} />
    </>
  )
}

export default appWithTranslation(MyApp)
